import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["cart"];

  initialize() {
    //console.log('Cart in the game');
  }

  refresh() {
    console.log("Refresh cart");
    this.application.getControllerForElementAndIdentifier();
  }
}
